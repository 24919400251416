import React from 'react'
import { twMerge } from 'tailwind-merge'

type OnboardingTitleProps = {
  className?: string
}

const H1: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({ children, className }) => (
  <h1
    className={twMerge(
      'mb-8 font-sans text-2xl font-bold text-black sm:text-3xl md:mb-14 md:text-4xl',
      className
    )}
  >
    {children}
  </h1>
)

const H2: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({ children, className }) => (
  <h2 className={twMerge('mb-6 border-b border-grey-100 font-bold', className)}>{children}</h2>
)

const H3: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({ children, className }) => (
  <h2 className={twMerge('mb-3 font-semibold', className)}>{children}</h2>
)

const H2Products: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({
  children,
  className,
}) => <h2 className={twMerge('mb-3 text-xl font-bold md:text-2xl', className)}>{children}</h2>

const H3Products: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({
  children,
  className,
}) => <h3 className={twMerge('mb-1 font-semibold md:text-lg', className)}>{children}</h3>

const H2Pricing: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({
  children,
  className,
}) => <h2 className={twMerge('mb-1 text-lg font-bold md:text-xl', className)}>{children}</h2>

const H3Pricing: React.FC<React.PropsWithChildren<OnboardingTitleProps>> = ({
  children,
  className,
}) => <h3 className={twMerge('font-semibold md:text-lg', className)}>{children}</h3>

export const OnboardingTitle = {
  H1,
  H2,
  H3,
  H2Products,
  H3Products,
  H2Pricing,
  H3Pricing,
}
