import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { Checkbox, Card } from '@olino/design-system'

interface ProductCardProps {
  icon: IconDefinition
  name: string
  onClick: () => void
}

const IntentionCard: React.FC<ProductCardProps> = ({ icon, name, onClick }) => {
  const [intention, setIntention] = useState<boolean>(false)

  return (
    <button
      type="button"
      className="group h-full w-full focus:outline-none"
      key={name}
      onClick={() => {
        setIntention(!intention)
        onClick()
      }}
    >
      <Card
        className={twMerge(
          'flex h-full w-full flex-row items-center px-4 py-3 text-left transition hover:-translate-y-1 hover:shadow-clone group-hover:bg-[#FAFAFA]'
        )}
      >
        <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-primary-50">
          <FontAwesomeIcon icon={icon} className={twMerge('h-6 w-6 text-black transition')} />
        </div>
        <div className="ml-4 mr-4 flex-grow">
          <p className="mb-0.5 flex-grow text-lg leading-tight tracking-tight text-black">{name}</p>
        </div>
        <Checkbox value={intention} />
      </Card>
    </button>
  )
}

export default IntentionCard
