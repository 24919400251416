import {
  type IconDefinition,
  faEye,
  faMoneyBill1,
  faObjectGroup,
  faShield,
} from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { track } from '../../utils/analytics'
import IntentionCard from '../components/IntentionCard'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'

const options: {
  label: string
  icon: IconDefinition
}[] = [
  // {
  //   label: 'Je lance mon entreprise et je veux la protéger',
  //   icon: faShield,
  // },
  {
    label: 'Je cherche de meilleurs tarifs',
    icon: faMoneyBill1,
  },
  // {
  //   label: 'Je veux regrouper des contrats existants',
  //   icon: faObjectGroup,
  // },
  {
    label: 'Je ne fais que regarder',
    icon: faEye,
  },
]

const Main = () => {
  const { id } = useParams()
  const { proceed } = useOnboardingWorkflow()

  const [intentions, setIntentions] = useState<string[]>([])

  const handleSelect = (intention: string) => {
    if (intentions.includes(intention)) {
      setIntentions(intentions.filter((value) => value !== intention))
    } else {
      setIntentions([...intentions, intention])
    }
  }

  const onSubmit = () => {
    track({
      event: 'submitted_intention',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_intention',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Comment pouvons-nous vous aider aujourd'hui&nbsp;?</OnboardingTitle.H1>

      <div className="space-y-8">
        <div className="flex flex-col items-stretch justify-center gap-3">
          {options.map((option) => (
            <IntentionCard
              key={option.label}
              onClick={() => handleSelect(option.label)}
              name={option.label}
              icon={option.icon}
            />
          ))}
        </div>

        <div className="flex justify-end">
          <OnboardingNextButton disabled={intentions.length === 0} onClick={onSubmit} />
        </div>
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Vous n’aimez pas le spam ?"
      content={[
        'Nous non plus ! Nous utilisons cette information pour adapter notre suivi à vos besoins et vous contacter que si nous pensons avoir une réelle valeur ajoutée. Bien évidemment, vous pouvez toujours consulter nos équipes quand vous le souhaitez avec le bouton ci-dessous.',
      ]}
    />
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
