import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Badge, Card, CardContent } from '@olino/design-system'

interface Props {
  title: string
  checked: boolean
  recommended?: boolean
  description: JSX.Element
}

export const PricingASDPackageCard: React.FC<Props> = ({
  title,
  checked,
  recommended = false,
  description,
}) => (
  <Card
    className={twMerge(
      'cursor-pointer transition hover:border-primary-900',
      checked && 'border-primary-900 shadow-clone-xl'
    )}
  >
    <CardContent className="flex flex-row items-center">
      <div
        className={twMerge(
          'mr-3 flex h-7 w-7 items-center justify-center rounded-full border border-grey-300 sm:mr-5'
        )}
      >
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={twMerge(
            'h-7 w-7 text-primary-600',
            'transition-transform ease-in-out',
            checked ? 'rotate-0 scale-100' : 'rotate-90 scale-0'
          )}
        />
      </div>

      <div className="flex flex-grow flex-col sm:flex-row sm:items-center">
        <div className="flex-grow">
          <div className="flex flex-row flex-wrap items-center">
            <h2 className="w-full text-lg font-bold sm:mr-2 sm:w-auto sm:text-xl">{title}</h2>
            {recommended ? (
              <Badge size="small" className="mr-2">
                Recommandé
              </Badge>
            ) : null}
          </div>
          <div className="hidden text-sm sm:block">{description}</div>
        </div>

        <div className="my-1 text-sm font-light sm:hidden">{description}</div>
      </div>
    </CardContent>
  </Card>
)
