import { faCircleCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lottie from 'lottie-react'
import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { ProductEnum } from '../../generated/graphql'
import { useQuotation } from '../context'
import animation from '../../assets/lottie/loading-animation-no-background.json'
import { useOnboardingWorkflow } from '../workflow'
import { track } from '../../utils/analytics'

const delayRange = [0.5, 1] as const // seconds

const getRandomDelay = () => {
  return Math.random() * (delayRange[1] - delayRange[0]) + delayRange[0]
}

const Main = () => {
  const { id } = useParams()
  const { quotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()

  const [steps] = useState(
    (quotation.details.products?.includes(ProductEnum.MultiRisk) &&
      quotation.premium?.multiRisk == null) ||
      (quotation.details.products?.includes(ProductEnum.PublicLiability) &&
        quotation.premium?.publicLiability == null) ||
      (quotation.details.products?.includes(ProductEnum.PublicLiabilityEcomm) &&
        quotation.premium?.publicLiabilityEcomm == null)
      ? ['Analyse de vos risques', 'Personnalisation de votre contrat', 'Calcul du meilleur tarif']
      : ['Calcul du meilleur tarif']
  )

  const [stepsStart, setStepsStart] = useState<boolean[]>(Array(steps.length).fill(false))

  const onProceed = () => {
    track({
      event: 'submitted_loading',
      quotation_id: id,
    })

    proceed()
  }

  // Hack to make sure the animation is played
  useEffect(() => {
    setStepsStart(([, ...rest]) => [true, ...rest])
  }, [])

  useEffect(() => {
    track({
      event: 'visited_loading',
      quotation_id: id,
    })
  }, [id])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Lottie animationData={animation} loop className="mr-8 h-72 w-72" />
      {steps.map((label, i, array) => (
        <Transition key={`step-${label}`} show={stepsStart[i]} className="text-lg">
          <Transition.Child
            key={label}
            appear
            enter="transition-all transform duration-1000 ease-in-out"
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
            className="flex flex-row items-center"
          >
            <div className="relative mr-2 h-4 w-4">
              <Transition.Child
                appear
                enter="transition-opacity ease-in-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                className="absolute inset-0 scale-95"
              >
                {/*<FontAwesomeIcon icon={faSpinner} className="absolute animate-spin text-grey-700" />*/}
              </Transition.Child>
              <Transition.Child
                appear
                enter="transition-all ease-in-out transform duration-200"
                enterFrom="opacity-0 scale-30 -translate-x-8"
                enterTo="opacity-100 scale-110 translate-x-0"
                className="absolute inset-0"
                style={{
                  transitionDelay: `${getRandomDelay()}s`,
                }}
                afterEnter={() => {
                  if (i === array.length - 1) {
                    setTimeout(() => onProceed(), getRandomDelay() * 1000)
                  } else {
                    setStepsStart((prev) => prev.map((v, j) => (j === i + 1 ? true : v)))
                  }
                }}
              >
                <FontAwesomeIcon icon={faCircleCheck} className="absolute text-primary-600" />
              </Transition.Child>
            </div>
            <span className="text-left font-medium">{label}</span>
          </Transition.Child>
        </Transition>
      ))}
    </div>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
