import { faArrowRight, faShieldCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { type OnboardingPage } from '../routes'
import { track } from '../../utils/analytics'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { useOnboardingWorkflow } from '../workflow'

import { Button } from '@olino/design-system'
import { useAuth } from '@olino/auth-client/react'

const Main = () => {
  const { proceed } = useOnboardingWorkflow()
  const { id } = useParams()

  const { isLoggedIn } = useAuth()

  const onSubmit = () => {
    track({
      event: 'submitted_welcome_page',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_welcome_page',
      quotation_id: id,
    })
  }, [id])

  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-1 items-start justify-end text-primary-400">
        {!isLoggedIn && (
          <Button variant="text" asChild>
            <Link to="/auth">Me connecter</Link>
          </Button>
        )}
      </div>
      <div>
        <OnboardingTitle.H1 className="text-3xl sm:text-4xl xl:text-5xl">
          Protégez votre activité
          <br />
          <span className="text-primary-400">en quelques minutes !</span>
        </OnboardingTitle.H1>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-center gap-8 sm:hidden">
            <OnboardingNextButton onClick={onSubmit}>Mon devis gratuit !</OnboardingNextButton>
          </div>

          <ul className="fa-ul space-y-2 text-primary-400 xl:text-xl">
            <li>
              <FontAwesomeIcon icon={faShieldCheck} className="fa-li" />
              <b>Attestations à portée de main</b> où que vous soyez
            </li>

            <li>
              <FontAwesomeIcon icon={faShieldCheck} className="fa-li" />
              <b>Déclaration en ligne</b> de sinistre en 2 minutes
            </li>

            <li>
              <FontAwesomeIcon icon={faShieldCheck} className="fa-li" />
              <b>Résiliation gratuite</b> de votre ancien contrat
            </li>

            <li>
              <FontAwesomeIcon icon={faShieldCheck} className="fa-li" />
              <b>Un expert disponible</b> à votre écoute
            </li>
          </ul>

          <div className="font-light text-grey-400">
            Vos informations sont uniquement utilisées pour vous proposer une couverture et des prix
            personnalisés.
          </div>

          <div className="hidden flex-col items-center gap-4 sm:flex sm:flex-row sm:justify-between">
            <OnboardingNextButton onClick={onSubmit}>
              Mon devis gratuit !<FontAwesomeIcon icon={faArrowRight} />
            </OnboardingNextButton>
          </div>
        </div>
      </div>
      <div className="flex-1" />
    </div>
  )
}

const Tips = () => {
  return (
    <div className="flex h-full flex-col gap-8">
      {/* <div>
        <b>+2 000</b> entreprises nous font confiance
      </div>

      <TipsCard title="Mathieu - Assuré par Olino" titleIcon={faUser}>
        <h3 className="mb-2 font-bold">👍 Super</h3>

        <p className="italic">
          "Bonjour, très bonne experience avec des tarifs très corrects et super efficace. Mon
          attestation en 5 min chrono"
        </p>
      </TipsCard>

      <div className="spacer flex-1" /> */}
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
