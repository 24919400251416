import posthog from 'posthog-js'
import { type SetRequired } from 'type-fest'

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}

type User = {
  id?: string
  email: string
}

type Event = SetRequired<
  Partial<
    | {
        event: 'visited_welcome_page'
        quotation_id: string
      }
    | {
        event: 'submitted_welcome_page'
        quotation_id: string
      }
    | {
        event: 'visited_company_autocomplete'
        quotation_id: string
      }
    | {
        event: 'submitted_company_autocomplete'
        quotation_id: string
      }
    | {
        event: 'visited_company_details'
        quotation_id: string
      }
    | {
        event: 'submitted_company_details'
        quotation_id: string
      }
    | {
        event: 'visited_turnover'
        quotation_id: string
      }
    | {
        event: 'submitted_turnover'
        quotation_id: string
      }
    | {
        event: 'visited_personal_info'
        quotation_id: string
      }
    | {
        event: 'submitted_personal_info'
        quotation_id: string
      }
    | {
        event: 'visited_intention'
        quotation_id: string
      }
    | {
        event: 'submitted_intention'
        quotation_id: string
      }
    | {
        event: 'visited_product'
        quotation_id: string
      }
    | {
        event: 'submitted_product'
        quotation_id: string
      }
    | {
        event: 'visited_activities'
        quotation_id: string
      }
    | {
        event: 'submitted_activities'
        quotation_id: string
      }
    | {
        event: 'visited_heyflow'
        quotation_id: string
      }
    | {
        event: 'submitted_heyflow'
        quotation_id: string
      }
    | {
        event: 'visited_ecommerce_turnover'
        quotation_id: string
      }
    | {
        event: 'submitted_ecommerce_turnover'
        quotation_id: string
      }
    | {
        event: 'visited_mrp_building_address'
        quotation_id: string
      }
    | {
        event: 'submitted_mrp_building_address'
        quotation_id: string
      }
    | {
        event: 'visited_mrp_building_informations'
        quotation_id: string
      }
    | {
        event: 'submitted_mrp_building_informations'
        quotation_id: string
      }
    | {
        event: 'visited_loading'
        quotation_id: string
      }
    | {
        event: 'submitted_loading'
        quotation_id: string
      }
    | {
        event: 'visited_tailored_cover_date'
        quotation_id: string
      }
    | {
        event: 'submitted_tailored_cover_date'
        quotation_id: string
      }
    | {
        event: 'visited_tailored_book_meeting'
        quotation_id: string
      }
    | {
        event: 'submitted_tailored_book_meeting'
        quotation_id: string
      }
    | {
        event: 'visited_tailored_finalization'
        quotation_id: string
      }
    | {
        event: 'visited_pricing_page'
        quotation_id: string
      }
    | {
        event: 'submitted_pricing_page'
        quotation_id: string
      }
    | {
        event: 'visited_termination'
        quotation_id: string
      }
    | {
        event: 'submitted_termination'
        quotation_id: string
      }
    | {
        event: 'visited_start_date'
        quotation_id: string
      }
    | {
        event: 'submitted_start_date'
        quotation_id: string
      }
    | {
        event: 'visited_summary'
        quotation_id: string
      }
    | {
        event: 'submitted_summary'
        quotation_id: string
      }
    | {
        event: 'visited_authenticate'
        quotation_id: string
      }
    | {
        event: 'submitted_authenticate'
        quotation_id: string
      }
    | {
        event: 'visited_otp'
        quotation_id: string
      }
    | {
        event: 'submitted_otp'
        quotation_id: string
      }
    | {
        event: 'visited_checkout'
        quotation_id: string
      }
    | {
        event: 'submitted_checkout'
        quotation_id: string
      }
    | {
        event: 'visited_signature'
        quotation_id: string
      }
    | {
        event: 'submitted_signature'
        quotation_id: string
      }
    | {
        event: 'visited_success_page'
        quotation_id: string
      }
    // -----
    | {
        event: 'contract_document_downloaded' // TODO: USE THIS EVENT
        user_id: string
        contract_id: string
        document_type: string
      }
    | {
        event: 'quote_document_downloaded' // TODO: USE THIS EVENT
        quotation_id: string
        user_id: string
        document_type: string
      }
    | {
        event: 'sinister_reported' // TODO: USE THIS EVENT
        user_id: string
        sinister_id: string
      }
    | {
        event: 'contract_certificate_obtained' // TODO: USE THIS EVENT
        user_id: string
        contract_id: string
        product: string
      }
    | {
        event: 'quote_edited' // TODO: USE THIS EVENT
        quotation_id: string
        user_id: string
      }
    | {
        event: 'new_quote_created'
        quotation_id: string
      }
    | {
        event: 'meeting_booked'
        quotation_id: string
      }
    | {
        event: 'activity_selected'
        quotation_id: string
        activity_id: number
        product: string
      }
    | {
        event: 'activity_not_found'
        quotation_id: string
        product: string
      }
    // -----
    | {
        event: 'sign_up'
      }
    | {
        event: 'sees_pricing'
        quotation_id: string
        company_name: string
        total_premium: number
        product: string
      }
    | {
        event: 'cant_see_pricing'
        quotation_id: string
        company_name: string
      }
    | {
        event: 'meeting_scheduled'
        company_name: string
      }
    | {
        event: 'subscribe'
        quotation_id: string
        company_name: string
        total_premium: number
      }
    | {
        event: 'activity_selected'
        product: string
        activity_id: number
        activity: string
      }
    | {
        event: 'activity_not_found'
        product: string
      }
    | {
        event: 'fill_company'
        company_name: string
        siret: string
        turnover: number
        usaCanadaTurnover: number
        companySizeLabel: string
      }
    | {
        event: 'fill_user'
        first_name: string
        last_name: string
        email: string
        phone: string
        marketing_opt_in: boolean
      }
    | {
        event: 'sees_product_selection'
        company_id: string
      }
    | {
        event: 'select_product'
        product: string
        quotation_id: string
        company_name: string
      }
    | {
        event: 'sees_welcome'
      }
    | {
        event: 'finish_welcome'
      }
    | {
        event: 'downloads_document'
        quotation_id: string
        document_type: string
      }
    | {
        event: 'sees_intention'
        company_id: string
      }
    | {
        event: 'fill_intention'
        intentions: string[]
        company_id: string
      }
    | {
        event: 'visited_asd_building_address'
        quotation_id: string
      }
    | {
        event: 'submitted_asd_building_address'
        quotation_id: string
      }
  >,
  'event'
>

window.dataLayer = window.dataLayer || []

let savedUser: User | null

const identify = (newUser: User | null = null) => {
  savedUser = newUser
  window.dataLayer.push({
    user_id: savedUser?.id,
    user_email: savedUser?.email,
  })

  if (newUser) {
    posthog.identify(newUser.email, {
      user_id: newUser.id,
    })
  } else {
    posthog.reset()
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const track = ({ event, ...payload }: Event, options?: Record<string, never>) => {
  const userData = {
    user_id: savedUser?.id,
    user_email: savedUser?.email,
  }

  window.dataLayer.push({
    event,
    ...payload,
    ...userData,
  })

  posthog.capture(event, {
    ...payload,
  })
}

export { identify, track }
