import { Navigate, Route, Routes } from 'react-router'

import WelcomePage from './pages/00-Welcome'
import CompanyPage from './pages/01-Company'
import CompanyDetailsPage from './pages/02-CompanyDetails'
import TurnoverPage from './pages/03-Turnover'
import PersonalInformationsPage from './pages/04-PersonalInformations'
import IntentionsPage from './pages/05-Intention'
import ProductsPage from './pages/06-Products'
import ActivityPage from './pages/07-Activity'
import HeyflowPage from './pages/08-Heyflow'
import EcommerceTurnoverPage from './pages/09-EcommerceTurnover'
import MRPBuildingAddressPage from './pages/10-MRPBuildingAddress'
import MRPBuildingInformationsPage from './pages/11-MRPBuildingInformations'
import LoadingPage from './pages/12-Loading'
import TailoredCoverDatePage from './pages/13-TailoredCoverDate'
import TailoredBookMeetingPage from './pages/14-TailoredBookMeeting'
import TailoredFinalizationPage from './pages/15-TailoredFinalization'
import PricingRCPPage from './pages/16-PricingRCP'
import PricingEcommPage from './pages/17-PricingEcomm'
import PricingMRPPage from './pages/18-PricingMRP'
import TerminationPage from './pages/19-Termination'
import StartDatePage from './pages/20-StartDate'
import SummaryPage from './pages/21-Summary'
import AuthenticatePage from './pages/22-Authenticate'
import OtpPage from './pages/23-Otp'
import CheckoutPage from './pages/24-Checkout'
import SignaturePage from './pages/25-Signature'
import SuccessPage from './pages/26-Success'
import AsdBuildingAddressPage from './pages/27-ASDBuildingAddress'
import PersonalInformationAxaPage from './pages/28-PersonnalInformationAxa'
import FormuleAxaPage from './pages/29-FormuleAxa'
import PricingAsdPage from './pages/30-PricingASD'
import { type OnboardingWorkflowPath, useOnboardingWorkflow } from './workflow'

type OnboardingRoute<T extends string = string> = {
  path: T
  main: React.ReactNode
  tips?: React.ReactNode
}

export type OnboardingPage = Pick<OnboardingRoute, 'main' | 'tips'>

const Catch = () => {
  const { nextStep, initialStep } = useOnboardingWorkflow()

  return <Navigate to={(nextStep || initialStep)?.[0] ?? '/'} replace />
}

// We might want to expand this config to be able to nest routes for context or other reasons.
const routes = [
  {
    path: 'welcome',
    ...WelcomePage,
  },
  {
    path: 'company',
    ...CompanyPage,
  },
  {
    path: 'company-details',
    ...CompanyDetailsPage,
  },
  {
    path: 'turnover',
    ...TurnoverPage,
  },
  {
    path: 'personal-informations',
    ...PersonalInformationsPage,
  },
  {
    path: 'intentions',
    ...IntentionsPage,
  },
  {
    path: 'activity',
    ...ActivityPage,
  },
  {
    path: 'products',
    ...ProductsPage,
  },
  {
    path: 'heyflow',
    ...HeyflowPage,
  },
  {
    path: 'ecommerce-turnover',
    ...EcommerceTurnoverPage,
  },
  {
    path: 'mrp-building-address',
    ...MRPBuildingAddressPage,
  },
  {
    path: 'mrp-building-information',
    ...MRPBuildingInformationsPage,
  },
  {
    path: 'loading',
    ...LoadingPage,
  },
  {
    path: 'tailored--cover-date',
    ...TailoredCoverDatePage,
  },
  {
    path: 'tailored--book-meeting',
    ...TailoredBookMeetingPage,
  },
  {
    path: 'tailored--finalization',
    ...TailoredFinalizationPage,
  },
  {
    path: 'pricing-rcp',
    ...PricingRCPPage,
  },
  {
    path: 'pricing-ecomm',
    ...PricingEcommPage,
  },
  {
    path: 'pricing-mrp',
    ...PricingMRPPage,
  },
  {
    path: 'termination',
    ...TerminationPage,
  },
  {
    path: 'start-date',
    ...StartDatePage,
  },
  {
    path: 'summary',
    ...SummaryPage,
  },
  {
    path: 'authenticate',
    ...AuthenticatePage,
  },
  {
    path: 'otp',
    ...OtpPage,
  },
  {
    path: 'checkout',
    ...CheckoutPage,
  },
  {
    path: 'signature',
    ...SignaturePage,
  },
  {
    path: 'success',
    ...SuccessPage,
  },
  {
    path: 'asd-building-address',
    ...AsdBuildingAddressPage,
  },
  {
    path: 'formuleAxa',
    ...FormuleAxaPage,
  },
  {
    path: 'personal-information-axa',
    ...PersonalInformationAxaPage,
  },
  {
    path: 'pricing-asd',
    ...PricingAsdPage,
  },
  {
    path: '*',
    main: <Catch />,
    tips: null,
  },
] as const satisfies readonly OnboardingRoute<OnboardingWorkflowPath | '*'>[]

export const MainContentRoutes = () => (
  <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.main} />
    ))}
  </Routes>
)

export const TipsRoutes = () => (
  <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.tips} />
    ))}
  </Routes>
)
