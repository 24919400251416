import { RadioGroup } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { useQuotation } from '../context'
import { AssistanceDomestiquePackage } from '../../generated/graphql'

import { PricingASDPackageCard } from './PricingASDPackageCard'

const packages: Record<AssistanceDomestiquePackage, { name: string; description: JSX.Element }> = {
  [AssistanceDomestiquePackage.Basic]: {
    name: 'Basic',
    description: (
      <p>
        La formule <b>Basic</b> comporte l'assistance sur l'éléctricité, la serrurie / Vitrerie et
        la plomberie intérieur/extérieur
      </p>
    ),
  },
  [AssistanceDomestiquePackage.Premium]: {
    name: 'Premium',
    description: (
      <p>
        La formule <b>Premium</b> comporte l'assistance sur l'élétricité, la serrurie / Vitrerie, la
        plomberie intérieur/exterieure et le chauffage
      </p>
    ),
  },
}

export const PricingASDPackageSection = React.forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { quotation, updateQuotation } = useQuotation()

    const details = quotation.details.assistanceDomestique

    return (
      <section className={twMerge(className)} id="package" ref={ref}>
        <RadioGroup
          value={details?.packageOptionAssistance ?? undefined}
          onChange={(value: AssistanceDomestiquePackage) =>
            updateQuotation({
              details: {
                assistanceDomestique: {
                  packageOptionAssistance: value,
                },
              },
            })
          }
        >
          <div className="space-y-3">
            <h2 className="text-lg font-bold md:text-xl">
              Votre formule pour modifier votre protection
            </h2>
            {[
              Object.values(AssistanceDomestiquePackage).map((value) => (
                <RadioGroup.Option value={value} key={value}>
                  {({ checked }) => (
                    <PricingASDPackageCard
                      key={value}
                      title={packages[value].name}
                      description={packages[value].description}
                      checked={checked}
                    />
                  )}
                </RadioGroup.Option>
              )),
            ]}
          </div>
        </RadioGroup>
      </section>
    )
  }
)
