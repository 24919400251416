export enum Currency {
  Euro = 'EURO',
}

export type MoneyBag = {
  taxExcluded: number
  taxIncluded: number
  currency: Currency
}

export enum ProductEnum {
  AssistanceDomestique = 'ASSISTANCE_DOMESTIQUE',
  PublicLiability = 'PUBLIC_LIABILITY',
  PublicLiabilityEcomm = 'PUBLIC_LIABILITY_ECOMM',
  MultiRisk = 'MULTI_RISK',
  Cyber = 'CYBER',
  Auto = 'AUTO',
  Legal = 'LEGAL',
  Decennial = 'DECENNIAL',
  KeyPerson = 'KEY_PERSON',
  DAndO = 'D_AND_O',
  Health = 'HEALTH',
  Life = 'LIFE',
  AutoMission = 'AUTO_MISSION',
  Other = 'OTHER',
}

export enum CountryEnum {
  France = 'FRANCE',
}

export enum PaymentFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
}

export * as MultiRisk from './multi-risk'
export * as AssistanceDomestique from './assistance-domestique'
