import React from 'react'

import CoverListItem from '../../components/quotation/coverListItem/CoverListItem'
import CoverListOption from '../../components/quotation/coverListOption/CoverListOption'
import { useQuotation } from '../context'

type Props = React.PropsWithChildren & {
  displayOnlyCover?: boolean
}

export const PricingASDCoversOptions = ({ displayOnlyCover }: Props) => {
  const { quotation } = useQuotation()

  const covers = quotation.covers?.assistanceDomestique
  const details = quotation.details.assistanceDomestique
  const premium = quotation.premium?.assistanceDomestique

  return (
    <div className="space-y-3">
      <CoverListItem label="Electricité" amount={covers?.electricite} />
      <CoverListItem label="Serrurie / Vitrerie" amount={covers?.serrurieVitrerie} />
      <CoverListItem label="Plomberie intérieure/extérieure" amount={covers?.plomberie} />
      <CoverListItem label="Chauffage" amount={covers?.chauffage} />
    </div>
  )
}
