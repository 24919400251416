import { type IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import Calendly from '../../components/Calendly'
import { PRODUCTS } from '../../constants/products'
import { useQuotation } from '../context'
import { ProductEnum } from '../../generated/graphql'
import { track } from '../../utils/analytics'
import usePartner from '../../utils/usePartner'
import { FormuleCard } from '../components/FormuleCard'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'

import { Button, Loader } from '@olino/design-system'

const categories: Array<{
  name: string
  as: 'h2' | 'h3'
  description?: string
  products: Array<{ title: string; description: [string]; value: ProductEnum }>
}> = [
  {
    name: 'Olino Autonomie',
    as: 'h2',
    description: 'Choisissez les produits qui correspondent à votre activité.',
    products: [
      {
        title: 'Formule Standard',
        description: ['Electricité\n ' + '\nSerrurie / Vitrerie Plomberie intérieur/extérieur'],
        value: ProductEnum.PublicLiability,
      },
      {
        title: 'Formule Premium',
        description: ['Electricité\n ' + '\nSerrurie / Vitrerie Plomberie intérieur/extérieur'],
        value: ProductEnum.MultiRisk,
      },
    ],
  },
]

const Main = () => {
  const { id } = useParams()
  const { updateQuotation } = useQuotation()
  const { partner, code: partnerCode } = usePartner()
  const { proceed } = useOnboardingWorkflow()

  const handleSelect = (product: ProductEnum) => {
    track({
      event: 'submitted_product',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          products: [product],
        },
      })
    )
  }

  useEffect(() => {
    track({
      event: 'visited_product',
      quotation_id: id,
    })
  }, [id])

  if (partnerCode !== null && partner === null) return <Loader />

  return (
    <>
      <OnboardingTitle.H1 className="text-3xl sm:text-4xl xl:text-5xl">
        Protégez-vous des imprévus
        <br />
        <span className="text-primary-400">en quelques minutes!</span>
      </OnboardingTitle.H1>
      <OnboardingTitle.H3>
        <span className="text-black">
          Avec l'assistance Dépannage Express de notre partenaire Axa, bénéficiez d'une prise en
          charge 24h/24 et 7j/7 en cas de panne.{' '}
        </span>
      </OnboardingTitle.H3>

      <section className="space-y-8">
        {categories.map((category) => {
          const productList = partner
            ? category.products.filter(
                (product) =>
                  partner?.products &&
                  partner.products.some(
                    (partnerProduct) =>
                      partnerProduct.product === product.value &&
                      partnerProduct.displayDuringQuotation
                  )
              )
            : category.products

          const isCategoryEmpty = partner && productList.length === 0

          if (isCategoryEmpty) return null

          return (
            <div key={category.name}>
              {/* {!isCategoryEmpty && category.as === 'h2' ? (
                <OnboardingTitle.H2Products>{category.name}</OnboardingTitle.H2Products>
              ) : (
                <OnboardingTitle.H3Products>{category.name}</OnboardingTitle.H3Products>
              )} */}
              {/* {category.description && <p className="mb-3">{category.description}</p>} */}
              <div className="grid gap-3 text-black sm:grid-cols-2">
                {productList.map((product) => (
                  <FormuleCard
                    key={product.value}
                    onClick={() => handleSelect(product.value)}
                    name={product.title}
                    description={product.description}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </section>

      <div className="mt-7 text-center">
        <span>Vous avez un autre besoin ? </span>
        <Calendly type="popup" reason="cant-find-product">
          {(open) => (
            <Button variant="link" onClick={open}>
              Cliquez ici pour en discuter avec un de nos experts.
            </Button>
          )}
        </Calendly>
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <div className="space-y-3">
      <TipsCard
        title="Inclus dans toutes les formules"
        content={[
          'Plafond de prise en charge: 1000',
          "Nombre d'interventions par an: illimité",
          'Délai de carence : aucun',
        ]}
      />
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
