import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { PricingASDCoversSection } from '../components/PricingASDCoversSection'
import { PricingASDPackageSection } from '../components/PricingASDPackageSection'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useOnboardingWorkflow } from '../workflow'
import { PricingCardAssistanceDomestique } from '../components/PricingCardAssistanceDomestique'
import { FormattedPrice } from '../components/FormattedPrice'
import { track } from '../../utils/analytics'
import { PricingASDCoversOptions } from '../components/PricingASDCoversOptions'
import { useQuotation } from '../context'

import { Card, CardContent } from '@olino/design-system'

const Main = () => {
  const { id } = useParams()
  const { proceed } = useOnboardingWorkflow()
  const { quotation } = useQuotation()

  const onProceed = () => {
    track({
      event: 'submitted_pricing_page',
      quotation_id: id,
    })

    proceed()
  }

  useEffect(() => {
    track({
      event: 'visited_pricing_page',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Votre devis pour votre assistance domicile</OnboardingTitle.H1>

      <div className="space-y-14">
        <div className="block lg:hidden">
          <PricingCardAssistanceDomestique>
            <PricingASDCoversSection />
          </PricingCardAssistanceDomestique>
        </div>

        <Card>
          <CardContent className="space-y-8">
            <div className="flex justify-between">
              <div className="flex flex-col gap-1">
                <OnboardingTitle.H2Pricing>Protections essentielles</OnboardingTitle.H2Pricing>
                <span className="text-xs md:text-sm">
                  Ce sont les protections minimales dont vous ne pouvez vous passer.
                </span>
              </div>

              <div className="space-y-3">
                <FormattedPrice
                  size="sm"
                  originalAmount={quotation.premium?.multiRisk?.base}
                  hideLoader
                />
              </div>
            </div>

            <div className="space-y-3">
              <p>
                Nous incluons 8 garanties essentielles pour protéger au mieux votre entreprise :
              </p>
              <PricingASDCoversOptions displayOnlyCover />
            </div>
          </CardContent>
        </Card>

        <PricingASDPackageSection />

        <div className="flex flex-col gap-8">
          <div className="flex justify-end">
            <OnboardingNextButton onClick={() => onProceed()}>
              Souscrire
              <FontAwesomeIcon icon={faArrowRight} />
            </OnboardingNextButton>
          </div>
        </div>
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <div className="hidden lg:block">
      <PricingCardAssistanceDomestique>
        <PricingASDCoversSection />
      </PricingCardAssistanceDomestique>
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
