import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'

import { cn } from '../utils'
import { Loader } from '../loader-v2/LoaderV2'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full border-2 font-semibold normal-case tracking-tight text-opacity-90 transition focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-70 focus-visible:ring-offset-2',
  {
    variants: {
      color: {
        primary: '',
        secondary: '',
        danger: '',
      },
      variant: {
        contained: '',
        outlined: 'bg-transparent',
        text: 'border-transparent bg-transparent',
        link: '',
      },
      size: {
        sm: 'min-h-[32px] min-w-[32px] px-3 py-1.5 text-sm',
        md: 'min-h-[44px] min-w-[44px] px-6 py-2 text-base',
        lg: 'min-h-[72px] min-w-[72px] px-7 py-3 text-lg',
      },
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'contained',
        className:
          'border-secondary-500 bg-secondary-500 text-white ring-primary-500 hover:border-secondary-400 hover:bg-secondary-400',
      },
      {
        color: 'primary',
        variant: 'outlined',
        className: 'border-primary-500 text-primary-950 ring-primary-500 hover:bg-primary-100',
      },
      {
        color: 'primary',
        variant: 'text',
        className: 'border-transparent text-primary-900 ring-primary-500 hover:bg-primary-100',
      },
      {
        color: 'danger',
        variant: 'text',
        className: 'border-transparent text-danger-950 ring-danger-500 hover:bg-danger-100',
      },
      {
        variant: 'link',
        className:
          'min-h-0 min-w-0 whitespace-normal border-0 p-0 text-primary-950 hover:underline focus-visible:underline focus-visible:ring-0',
      },
    ],
    defaultVariants: {
      color: 'primary',
      variant: 'contained',
      size: 'md',
    },
  }
)

type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    loading?: boolean
  }

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'primary',
      variant = 'contained',
      size = 'md',
      asChild,
      loading,
      disabled,
      className,
      children,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    // eslint-disable-next-line no-nested-ternary
    const loaderSize = size === 'lg' ? 'md' : size === 'md' ? 'sm' : size === 'sm' ? 'xs' : 'xs'

    return (
      <Comp
        ref={ref}
        className={cn(
          buttonVariants({ color, variant, size, className }),
          (loading || disabled) && 'pointer-events-none',
          disabled && 'opacity-50'
        )}
        disabled={loading || disabled}
        type={type}
        {...props}
      >
        {loading ? <Loader size={loaderSize} className="p-0" /> : children}
      </Comp>
    )
  }
)
Button.displayName = 'Button'
