import { createWorkflow } from '../contexts/workflow'
import { type WorkflowSteps } from '../contexts/workflow/types'
import { ProductEnum } from '../generated/graphql'

import { type Quotation } from './context'

import client from '@olino/auth-client/js'

type Path =
  | 'welcome'
  | 'company'
  | 'company-details'
  | 'turnover'
  | 'personal-informations'
  | 'intentions'
  | 'products'
  | 'activity'
  | 'heyflow'
  | 'ecommerce-turnover'
  | 'mrp-building-address'
  | 'mrp-building-information'
  | 'loading'
  | 'tailored--cover-date'
  | 'tailored--book-meeting'
  | 'tailored--finalization'
  | 'pricing-rcp'
  | 'pricing-ecomm'
  | 'pricing-mrp'
  | 'termination'
  | 'start-date'
  | 'summary'
  | 'authenticate'
  | 'otp'
  | 'checkout'
  | 'signature'
  | 'success'
  | 'asd-building-address'
  | 'formuleAxa'
  | 'personal-information-axa'
  | 'pricing-asd'

const isContactFilled = (q?: Quotation) => {
  const contact = q?.details.contact

  return (
    contact?.email != null &&
    contact?.firstName != null &&
    contact?.lastName != null &&
    contact?.phone != null &&
    contact?.marketingOptIn != null
  )
}

const steps: WorkflowSteps<Path, Quotation> = {
  welcome: {
    completed: false,
    goto: 'intentions',
    initial: true,
    hideBackwardButton: true,
  },

  intentions: {
    completed: false,
    goto: 'products',
  },

  turnover: {
    completed: false,
    goto: 'products',
  },

  products: {
    completed: false,
    goto: (q) => {
      if (
        q?.details.products?.some(
          (p) =>
            p === ProductEnum.PublicLiability ||
            p === ProductEnum.PublicLiabilityEcomm ||
            p === ProductEnum.MultiRisk
        )
      ) {
        return 'activity'
      }
      if (q?.details.products?.some((p) => p === ProductEnum.AssistanceDomestique)) {
        return 'asd-building-address'
      }

      return 'heyflow'
    },
  },

  activity: {
    completed: (q) =>
      !!q?.details.multiRisk?.activityId ||
      !!((q?.details.publicLiability?.activityIds?.length || 0) > 0),
    goto: (q) => {
      if (q?.details.products?.some((p: ProductEnum) => p === ProductEnum.PublicLiabilityEcomm)) {
        return 'ecommerce-turnover'
      }

      if (q?.details.products?.some((p: ProductEnum) => p === ProductEnum.MultiRisk)) {
        return 'mrp-building-address'
      }

      return isContactFilled(q) ? 'loading' : 'personal-informations'
    },
  },

  heyflow: {
    completed: false,
    goto: (q) => (isContactFilled(q) ? 'loading' : 'personal-informations'),
    hideBackwardButton: true,
  },
  formuleAxa: {
    completed: false,
    goto: (q) => (isContactFilled(q) ? 'loading' : 'asd-building-address'),
    hideBackwardButton: true,
  },
  'asd-building-address': {
    completed: (q) => q?.details.assistanceDomestique?.address != null,
    goto: () => 'personal-information-axa',
  },

  'ecommerce-turnover': {
    completed: false,
    goto: (q) => (isContactFilled(q) ? 'loading' : 'personal-informations'),
  },

  'mrp-building-address': {
    completed: (q) => q?.details.multiRisk?.address != null,
    goto: () => 'mrp-building-information',
  },

  'mrp-building-information': {
    completed: (q) =>
      q?.details.multiRisk?.contentValue != null ||
      q?.details.multiRisk?.historicalMonument != null ||
      q?.details.multiRisk?.occupancyType != null ||
      q?.details.multiRisk?.surfaceArea != null,
    goto: (q) => (isContactFilled(q) ? 'loading' : 'personal-informations'),
  },

  'personal-informations': {
    completed: false,
    goto: 'loading',
  },

  'personal-information-axa': {
    completed: false,
    goto: 'loading',
  },

  loading: {
    completed: false,
    hideBackward: true,
    hideBackwardButton: true,
    goto: (q) => {
      if (q?.details.products?.includes(ProductEnum.MultiRisk) && q?.premium?.multiRisk) {
        return 'pricing-mrp'
      }

      if (
        q?.details.products?.includes(ProductEnum.PublicLiability) &&
        q?.premium?.publicLiability
      ) {
        return 'pricing-rcp'
      }

      if (
        q?.details.products?.includes(ProductEnum.PublicLiabilityEcomm) &&
        q?.premium?.publicLiabilityEcomm
      ) {
        return 'pricing-ecomm'
      }

      if (q?.details.products?.includes(ProductEnum.AssistanceDomestique)) {
        return 'pricing-asd'
      }

      return 'tailored--cover-date'
    },
  },

  'tailored--cover-date': {
    completed: false,
    goto: () => 'tailored--finalization',
  },

  'tailored--book-meeting': {
    completed: false,
    goto: () => 'tailored--finalization',
  },

  'tailored--finalization': {
    completed: false,
  },

  'pricing-rcp': {
    completed: false,
    goto: () => 'start-date',
  },

  'pricing-ecomm': {
    completed: false,
    goto: () => 'start-date',
  },

  'pricing-mrp': {
    completed: false,
    goto: () => 'start-date',
  },

  termination: {
    completed: false,
    goto: () => 'start-date',
  },

  'pricing-asd': {
    completed: false,
    goto: () => 'start-date',
  },

  'start-date': {
    completed: false,
    goto: () => 'company',
  },

  company: {
    completed: false,
    goto: 'company-details',
  },

  'company-details': {
    completed: false,
    goto: 'summary',
  },

  summary: {
    completed: false,
    goto: (q) => {
      const currentUser = client.getCurrentUser()

      if (
        q?.details?.contact?.email &&
        currentUser?.email &&
        q?.details?.contact?.email === currentUser?.email
      )
        return 'checkout'

      return 'otp'
    },
  },

  authenticate: {
    completed: false,
    goto: () => 'otp',
  },

  otp: {
    completed: false,
    hideBackward: true,
    goto: () => 'checkout',
  },

  checkout: {
    completed: false,
    goto: () => 'signature',
  },

  signature: {
    completed: false,
    hideBackwardButton: true,
    goto: () => 'success',
  },

  success: {
    completed: false,
  },
}

const { provider, useContext } = createWorkflow<Path, Quotation>()

export {
  Path as OnboardingWorkflowPath,
  steps as OnboardingWorkflowSteps,
  provider as OnboardingWorkflowProvider,
  useContext as useOnboardingWorkflow,
}
