import React from 'react'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as TextLogo } from '../../assets/pilliot-text.svg'
import { ReactComponent as IconLogo } from '../../assets/pilliot-logo.svg'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LogoProps {
  className?: string
  variant: 'text' | 'icon'
}

export const Logo: React.FC<LogoProps> = ({ className, variant = 'text' }) => {
  const Comp = variant === 'text' ? TextLogo : IconLogo
  return <Comp className={twMerge(className)} />
}

Logo.displayName = 'Logo'
