import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { OnboardingTitle } from './OnboardingTitle'

import { Card, CardContent } from '@olino/design-system'

interface FormuleCardProps {
  name: string
  description: [string]
  onClick: () => void
}

export const FormuleCard: React.FC<FormuleCardProps> = ({ name, description, onClick }) => (
  <button
    type="button"
    className="group h-full w-full focus:outline-none"
    key={name}
    onClick={onClick}
  >
    <Card className="h-full w-full transition group-hover:-translate-y-1 group-hover:bg-[#FAFAFA] group-hover:shadow-clone">
      <CardContent className="flex flex-col items-center gap-2">
        <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-primary-50"></div>

        <div className="space-y-1">
          <OnboardingTitle.H3Products>{name}</OnboardingTitle.H3Products>
          <p className="text-sm font-light leading-tight">{description}</p>
        </div>
      </CardContent>
    </Card>
  </button>
)
