import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import { track } from '../../utils/analytics'
import links from '../../constants/links'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'

import { Checkbox, FormError, Input, PhoneInput } from '@olino/design-system'
import { replaceNullsWithUndefineds } from '@olino/utils'

// This is a duplicate of the WelcomePage component in src/pages/onboarding/00-Welcome/Welcome.tsx. The goal is to show how to use the new layout in the onboarding flow.
// When the new layout is ready, we will solely use this WelcomePage and remove the one in src/pages/onboarding/00-Welcome/Welcome.tsx.

const schema = z.object({
  firstName: z
    .string({ required_error: 'Ce champ est requis' })
    .trim()
    .min(1, { message: 'Le prénom est requis' }),
  lastName: z
    .string({ required_error: 'Ce champ est requis' })
    .trim()
    .min(1, { message: 'Le nom est requis' }),
  email: z
    .string({ required_error: 'Ce champ est requis' })
    .trim()
    .email({ message: "L'email n'est pas valide" }),
  phone: PhoneInput.schema,
  marketingOptIn: z.boolean().default(false),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { proceed } = useOnboardingWorkflow()
  const { quotation, updateQuotation } = useQuotation()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    defaultValues: replaceNullsWithUndefineds(quotation.details.contact ?? {}),
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    track({
      event: 'submitted_personal_info',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          contact: {
            ...data,
          },
        },
      })
    )
  }

  watch('email')
  watch('phone')

  useEffect(() => {
    track({
      event: 'visited_personal_info',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Vos informations de contact&nbsp;</OnboardingTitle.H1>

      <form className="w-full space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            <div>
              <Input
                type="text"
                label="Nom*"
                autoComplete="family-name"
                {...register('lastName')}
              />
              <FormError error={errors.lastName} />
            </div>

            <div>
              <Input
                type="text"
                label="Prénom*"
                autoComplete="given-name"
                {...register('firstName')}
              />
              <FormError error={errors.firstName} />
            </div>
          </div>

          <div className="md:col-span-3 lg:col-span-4">
            <Input
              type="email"
              label="Email*"
              autoComplete="email"
              placeholder="email@exemple.com"
              {...register('email')}
            />
            <FormError error={errors.email} />
          </div>

          <div className="md:col-span-3 lg:col-span-2">
            {/* <Input
                type="text"
                label="Téléphone"
                autoComplete="tel"
                placeholder="06 12 34 56 78"
                {...register('phone')}
              /> */}
            <Controller
              control={control}
              name="phone"
              render={({ field }) => <PhoneInput value={field.value} onChange={field.onChange} />}
            />
            <FormError error={errors.phone} />
          </div>

          <Controller
            name="marketingOptIn"
            control={control}
            render={({ field }) => (
              <div className="col-span-6 w-fit">
                <Checkbox
                  value={field.value}
                  onChange={(v) => field.onChange(v)}
                  name={field.name}
                  label="Je souhaite recevoir par voie électronique des offres commerciales personnalisées de la part de Pilliot Assurances, en ce qui concerne les assurances pour particuliers."
                  labelPosition="right"
                  labelClassName="text-justify font-light ml-3 text-xs sm:text-left sm:text-sm"
                />
                <FormError error={errors.marketingOptIn} />
              </div>
            )}
          />
        </div>

        <div className="flex flex-col items-center gap-3 md:flex-row md:justify-between">
          <div className="flex items-center text-xs text-info-400 sm:text-sm">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-3 h-4 w-4 flex-shrink-0" />
            <p className="text-justify font-light sm:text-left">
              En cliquant sur Suivant, vous confirmez avoir lu et accepté les{' '}
              <a href={links.CGU} className="font-semibold hover:underline">
                CGU
              </a>{' '}
              de Olino ainsi que notre{' '}
              <a href={links.POLITIQUE_CONFIDENTIALITE} className="font-semibold hover:underline">
                politique de confidentialité
              </a>{' '}
              applicable au traitement de vos données personnelles.
            </p>
          </div>

          <OnboardingNextButton disabled={!isValid} />
        </div>
      </form>
    </>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
