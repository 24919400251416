import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { useEffect } from 'react'

import { type OnboardingPage } from '../routes'
import AddressAutoComplete from '../../components/quotation/addressAutoComplete/AddressAutoComplete'
import { useQuotation } from '../context'
import { CountryEnum } from '../../generated/graphql'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingButtons } from '../components/OnboardingButtons'
import { track } from '../../utils/analytics'

import { FormError, Input } from '@olino/design-system'
import { TipsCard } from '../components/TipsCard'

// This is a duplicate of the WelcomePage component in src/pages/onboarding/00-Welcome/Welcome.tsx. The goal is to show how to use the new layout in the onboarding flow.
// When the new layout is ready, we will solely use this WelcomePage and remove the one in src/pages/onboarding/00-Welcome/Welcome.tsx.

const schema = z.object({
  line1: z
    .string({ required_error: 'Ce champ est requis' })
    .trim()
    .min(1, { message: 'Ce champ est requis' }),
  line2: z.string().optional(),
  city: z
    .string()
    .trim()
    .regex(/\D/, { message: 'Ce champ est requis et ne dois pas contenir de chiffre' })
    .min(1, { message: 'Ce champ est requis' }),
  postalCode: z
    .string({ required_error: 'Ce champ est requis' })
    .trim()
    .regex(/^\d{5}$/, { message: 'Le code postal est invalide' }),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { id } = useParams()
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    defaultValues: {
      line1: quotation.details?.assistanceDomestique?.address?.line1 ?? '',
      line2: quotation.details?.assistanceDomestique?.address?.line2 ?? '',
      city: quotation.details?.assistanceDomestique?.address?.city ?? '',
      postalCode: quotation.details?.assistanceDomestique?.address?.postalCode ?? '',
    },
    resolver: zodResolver(schema),
    mode: 'onTouched',
  })

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    track({
      event: 'submitted_asd_building_address',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          assistanceDomestique: {
            address: {
              ...data,
              country: CountryEnum.France,
            },
          },
        },
      })
    )
  }

  const onAutoCompleteChange = (data: { line1: string; city?: string; postalCode?: string }) => {
    setValue('line1', data.line1, { shouldValidate: true })

    if (data.city) setValue('city', data.city, { shouldValidate: true })
    if (data.postalCode) setValue('postalCode', data.postalCode, { shouldValidate: true })
  }

  useEffect(() => {
    track({
      event: 'visited_asd_building_address',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Quelle est l'adresse de votre logemment&nbsp;?</OnboardingTitle.H1>

      <form className="w-full space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <div>
            <Controller
              control={control}
              name="line1"
              render={({ field: { value, onChange } }) => (
                <AddressAutoComplete
                  onLine1Change={onChange}
                  onAddressChange={onAutoCompleteChange}
                  line1={value}
                  label="Mon logement est situé"
                />
              )}
            />
          </div>

          <div>
            <Input label="Complément d'adresse" {...register('line2')} />
            <FormError error={errors.line2} />
          </div>

          <div className="flex flex-col items-center gap-3 sm:flex-row">
            <div className="w-full sm:w-[25%]">
              <Input label="Code postal" {...register('postalCode')} />
              <FormError error={errors.postalCode} />
            </div>

            <div className="w-full sm:w-[75%]">
              <Input label="Ville" {...register('city')} />
              <FormError error={errors.city} />
            </div>
          </div>
        </div>

        <OnboardingButtons>
          <OnboardingNextButton disabled={!isValid} />
        </OnboardingButtons>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Rassurez-vous!"
      content={[
        "Vous n'avez pas besoin d'être assuré chez AXA pour souscrire à l'assistance Dépannage Express.",
        'Nous restons bien sûr disponible pour challanger votre contrat habitation actuel.',
      ]}
    ></TipsCard>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
