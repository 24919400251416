import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faBellExclamation, faBell, faBells } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'

type TipsCardProps = {
  title?: string
  titleIcon?: IconProp
  content?: string[]
}

export const TipsCard: React.FC<React.PropsWithChildren<TipsCardProps>> = ({
  title,
  titleIcon,
  content,
  children,
}) => {
  return (
    <div className="rounded-xl bg-primary-300 p-4 text-black">
      {title && (
        <h2 className="mb-3 space-x-1 text-lg font-bold">
          <FontAwesomeIcon icon={titleIcon ?? faBell} />
          <span className="text-black">{title}</span>
        </h2>
      )}

      <div className={twMerge('space-y-2 text-sm')}>
        {/* eslint-disable-next-line react/no-array-index-key */}
        {content && content.map((c, i) => <p key={`tips-${i}`}>{c}</p>)}
      </div>

      {children}
    </div>
  )
}
